export const KEY = "TSITCP2022"; //devcrazypizza
// export const KEY = "TSITCP2020"; // Production
// export const KEY = "TSITCP1234"; // Local
// export const HOST = "http://crazypizza.treosoftit.com/backend/api/";
export const HOST = "http://devcrazypizza.treosoftit.com/backend/api/";
// export const HOST = "http://localhost/backend/api/";
// export const HOST = "http://.treosoftit.com/backend/api/";
//
export const HOST_PREFIX = HOST.split("api")[0];
export const IMG_PATH_CATEGORY = "images/category";
export const IMG_PATH_DEALS = "images/deals";
export const IMG_PATH_PRODUCT = HOST_PREFIX + "/images/menu/";
export const CURRENCY = "fa fa-inr";
//GET
export const MENU_LIST = "Menu/listmenu";
export const DEALS_LIST = "Deals/listAllDeals";
export const DEALS_DETAILS_LIST = "Menu/getInfo";
export const ACTIVE_DEALS_LIST = "Deals/activeDeals";
export const DEAL_DETAILS = "Deals/dealDetails";
export const OFFERS_LIST = "Offer/listAllOffer";
export const SUBS_LIST = "subscription/listOfSubscription";
export const EMPLOYEES_LIST = "User/employeeByStore";
export const B_USER = "Business/listBusinessUsers";
export const B_USER_DETAILS = "Business/getBusinessDetails";
export const ACTIVATE_B_USER = "User/update";
export const APPROVE_B_USER = "Business/updateBusinessDetails";
export const EMPLOYEE_LIST = "User/employeeByStore";
export const DELIVERYBOYS_LIST = "/User/dbByStore?store_id=13&offset=0";
export const CATEGORY_LIST = "Category/listCategories";
export const EXPENSE_CATEGORY_LIST = "Expenses/listCategory";
export const EXPENSE_CATEGORY_ONLY_LIST = "Expenses/listCategoryOnly";
export const EXPENSE_SUB_CATEGORY_LIST = "Expenses/listSubCategory";
export const EXPENSE_CATEGORY_DATA = "Expenses/listExpenseCategory";
export const EXPENSE_SUB_CATEGORY_DATA = "Expenses/listExpenseSubCategory";
export const EXPENSE_SUB_CATEGORY_ITEM_DATA = "Expenses/listExpenseItemBoth";
export const EXPENSE_SUB_CATEGORY_ITEM_DATA_DROPDOWN = "Expenses/listExpenseItemBothDropDown";
export const EXPENSE_CATEGORY_NO_SUB = "Expenses/getCategoryListActive";
export const EXPENSE_CATEGORY_ITEM_DATA = "Expenses/listExpenseItemOnlyCategory";
export const EXPENSE_CATEGORY_ITEM_DATA_DROPDOWN = "Expenses/listExpenseItemOnlyCategoryDropDown";
export const DELETE_EXPENSES_CATEGORY = "Expenses/deleteExpenseCategory";
export const DELETE_EXPENSES_SUB_CATEGORY = "Expenses/deleteExpenseSubCategory";
export const DELETE_EXPENSES_ITEMS = "Expenses/deleteItems";
export const DELETE_EXPENSES = "Expenses/deleteExpenses";
export const TODAY_EXPENSE_LIST = "Expenses/getTodayExpenses";
export const TODAY_TOTAL_COST = "Expenses/getTodayTotalCost";
export const YESTERDAY_TOTAL_COST = "Expenses/getYesterdayTotalCost";
export const WEEK_TOTAL_COST = "Expenses/getWeekTotalCost";
export const MONTH_TOTAL_COST = "Expenses/getMonthTotalCost";
export const USER_LIST = `${HOST}User/usersList`;
export const PINCODE_LIST = "Pincode/listPincodes";
export const CUSTOMERS_LIST = "Customer/listCustomers";
export const ADD_CUSTOMERS = "Customer/updateCustomer";
export const SUBCATEGORY_LIST = "SubCategory/listSubCategoriesAdmin";
//POST
export const ADD_CATEGORY = "Category/updateCategory";
export const ADD_EXPENSES_CATEGORY = "Expenses/addCategory";
export const ADD_EXPENSES_SUB_CATEGORY = "Expenses/addSubCategory";
export const ADD_EXPENSES_ITEM = "Expenses/addItemList";
export const ADD_EXPENSES_DATA = "Expenses/addAllData";
export const UPDATE_EXPENSE_CATEGORY = "Expenses/updateCategories";
export const UPDATE_EXPENSE_SUB_CATEGORY = "Expenses/updateSubCategories";
export const UPDATE_EXPENSE_ITEMS = "Expenses/updateItems";
export const UPDATE_EXPENSE_BILL_IMAGE = "Expenses/uploadImage";
export const UPDATE_EXPENSES = "Expenses/updateExpenses";
export const DELETE_CATEGORY = "Category/deleteCategory";
export const ADD_SUBCATEGORY = "SubCategory/updateSubCategory"; 
export const DELETE_SUBCATEGORY = "Category/deleteSubCategory";
export const ADD_PRODUCT = "Menu/updateItem";
export const ADD_PRICE = "Menu/updatePrice";
export const ADD_PINCODE = "Category/updateCategory";
export const ADD_STORE = "Store/addStore";
export const UPDATE_STORE = "Store/updateStore";
export const CHECK_USER = "User/login";
export const ADD_USER_DETAILS = "User/addUser";
export const ADD_TO_CART = "Cart/updateCart";
export const REMOVE_FROM_CART = "Cart/deleteCartItem";
export const DELETE_CART = "Cart/deleteAllCart";
export const PLACE_ORDER = "Order/placeOrder";
export const UPDATE_ORDER = "Order/updateOrder";
export const NEW_DEAL = "Deals/updateDeal";
export const NEW_DEAL_DETAIL = "Deals/updateDealDetails";
export const NEW_OFFER = "Offer/updateOffer";
export const UPDATE_CATEGORY_IMAGE = "Category/uploadImage";
export const UPDATE_PRODUCT_IMAGE = "Menu/uploadImage";


//(REPORTS)
export const MONTHLY_REPORT = "Reports/getMonthlyReport";
export const HOURLY_REPORT = "Reports/getHourlySales";
export const TOP10_ITEMS = "Reports/getTopTenItems";
export const USERS = "Reports/getUserCount?role=user";
export const MISC = "Report/periodicSaleReport";
export const STORE_LIST = "Store/listStoreWithReport";
export const TOTAL_USER_COUNT = "Report/getUserCount";
export const UPDATE_USER_DETAILS = "User/update";
export const CATEGORY_REPORT = "Reports/getProductWithCatSale";
export const CATEGORY_WISE_EXPENSES_REPORT = "Expenses/getCategoryWiseReoprt";
export const SUB_CATEGORY_WISE_EXPENSES_REPORT = "Expenses/getSubCategoryWiseReoprt";
export const ITEM_WISE_EXPENSES_REPORT = "Expenses/getItemWiseReoprt";
export const ITEM_WISE_EXPENSES_REPORT_FOR_CATEGORY = "Expenses/getItemWiseReoprtNoSub";
export const DAY_WISE_DATA = "Expenses/getDayWiseReport";

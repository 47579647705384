import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../Card/Card";
import dateFormat from "dateformat";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CURRENCY } from "../../Network/ApiPaths";
import Chart from "./Chart";
// import Chart3 from "./Chart3";
// import Chart4 from "./Chart4";
import Chart21 from "./Chart21";
import Chart22 from "./Chart22";
import { MISC, HOURLY_REPORT, TOP10_ITEMS } from "../../Network/ApiPaths";
import Uri from "../../Network/Uri";
import ReactToPrint from "react-to-print";
import getResponseClient from "../../Network/GetClientDataResponse";
import axios from "axios";
import ExpensesChart from "./ExpensesChart";
// import qs from "qs";
// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let clientHost;
let storeFresh;
let years = [];
const userCheck = window.localStorage.getItem("access");
let storeDet = JSON.parse(userCheck);
let storeIdValue;
let storeAddress;
let storeName;
if (storeDet !== null) {
  storeIdValue = storeDet.storeId;
  storeAddress = storeDet.address;
  // storeName = storeDet.name;
} else {
  storeIdValue = "";
  storeAddress = "";
  // storeName="";
}
class Reports extends Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeIdValue = storeDet.storeId;
      storeAddress = storeDet.address;
    } else {
      storeIdValue = "";
      storeAddress = "";
    }
    // if (storeDet !== null) {
    //   storeAddress = storeDet.address;
    // } else {
    //   storeAddress = "";
    // }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeIdValue = storeFresh.storeId;
      // storeName = storeFresh.storeName;
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const cashierLimit = new Date(yesterday.toDateString());

    var currentYear = today.getFullYear();

    for (let year = 2018; year <= 2030; year++) {
      years.push(year);
    }

    this.state = {
      cashierLimit: dateFormat(cashierLimit, "yyyy-mm-dd"),
      topTen: [],
      bottomTen: [],
      misc: [],
      hourly: [],
      hDate: "",
      totalOrders: "",
      totalSales: "",
      netSales: "",
      average: "",
      date: "",
      dateTime: "",
      month: "",
      salesDate: "",
      grossCOD: "",
      netCOD: "",
      grossOnline: "",
      netOnline: "",
      grossCard: "",
      netCard: "",
      salesbydate: "",
      salesStartDate: "",
      salesEndDate: "",
      salesTotalOrders: "",
      salesTotalSales: "",
      salesNetSales: "",
      salesCGST: "",
      salesSGST: "",
      salesAverage: "",
      salesGrossCOD: "",
      salesGrossOnline: "",
      salesGrossCard: "",
      file: "",
      analyticsData: "",
      currentYear: currentYear
    };
  }
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  getDateFormat = () => {
    var date = new Date().toISOString().slice(0, 10);
    let dateTime = new Date().toLocaleString();
    this.setState({ date });
    // this.setState({ salesDate: new Date() });
    this.setState({
      salesDate: date,
      salesStartDate: date,
      salesEndDate: date,
      dateTime: dateTime,
    });
  };
  onsalesDateChange = (e) => {
    // var salesbydate = e.target.value;
    // let date = new Date(e.target.value).toISOString().slice(0, 10);
    // console.log("0",e.target.value,"1",date,"2",new Date(e.target.value))
    let salesbydate = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({
      salesDate: salesbydate,
    });
    var apiPath3 = new Uri(clientHost + `Report/getSalesData`)
      .addQueryParam("storeId", storeIdValue)
      .addQueryParam("startDate", salesbydate)
      .addQueryParam("endDate", salesbydate);
    getResponseClient(
      apiPath3,
      storeFresh.key,
      (onSuccessResponse) => {
        let salesData = onSuccessResponse.response.all;
        let salesDataCOD = onSuccessResponse.response.cod;
        let salesDataOnline = onSuccessResponse.response.online;
        let salesDataCard = onSuccessResponse.response.card;
        this.setState({
          totalOrders: salesData.OrdersCount,
          totalSales: salesData.GrossAmount,
          netSales: salesData.NetSale,
          // gst: Number(salesData.GrossAmount) - Number(salesData.NetSale),
          CGST: salesData.CGST,
          SGST: salesData.SGST,
          average: salesData.Average,
          grossCOD: salesDataCOD.GrossAmount,
          netCOD: salesDataCOD.NetSale,
          grossOnline: salesDataOnline.GrossAmount,
          netOnline: salesDataOnline.NetSale,
          grossCard: salesDataCard.GrossAmount,
          netCard: salesDataCard.NetSale,
        });
      },
      (onError) => {
        // console.log(onError);
      }
    );
  };
  onsalesStartDateChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    var salesStartDate = e.target.value;
    var salesEndDate =
      this.state.salesEndDate !== ""
        ? this.state.salesEndDate
        : this.state.date;
    var apiPath4 = new Uri(clientHost + `Report/getSalesData`)
      .addQueryParam("storeId", storeIdValue)
      .addQueryParam("startDate", salesStartDate)
      .addQueryParam("endDate", salesEndDate);
    getResponseClient(
      apiPath4,
      storeFresh.key,
      (onSuccessResponse) => {
        let salesData = onSuccessResponse.response.all;
        let salesDataCOD = onSuccessResponse.response.cod;
        let salesDataOnline = onSuccessResponse.response.online;
        let salesDataCard = onSuccessResponse.response.card;
        this.setState({
          salesTotalOrders: salesData.OrdersCount,
          salesTotalSales: salesData.GrossAmount,
          salesNetSales: salesData.NetSale,
          // salesGst: Number(salesData.GrossAmount) - Number(salesData.NetSale),
          salesCGST: salesData.CGST,
          salesSGST: salesData.SGST,
          salesAverage: salesData.Average,
          salesGrossCOD: salesDataCOD.GrossAmount,
          salesGrossOnline: salesDataOnline.GrossAmount,
          salesGrossCard: salesDataCard.GrossAmount,
        });
      },
      (onError) => {
        // console.log(onError);
      }
    );
  };

  handleYearChange = (e) => {
    this.setState({
      currentYear: parseInt(e.target.value, 10)
    })
  }

  onsalesEndDateChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    var salesStartDate =
      this.state.salesStartDate !== ""
        ? this.state.salesStartDate
        : this.state.date;
    var salesEndDate = e.target.value;
    var apiPath5 = new Uri(clientHost + `Report/getSalesData`)
      .addQueryParam("storeId", storeIdValue)
      .addQueryParam("startDate", salesStartDate)
      .addQueryParam("endDate", salesEndDate);
    getResponseClient(
      apiPath5,
      storeFresh.key,
      (onSuccessResponse) => {
        let salesData = onSuccessResponse.response.all;
        let salesDataCOD = onSuccessResponse.response.cod;
        let salesDataOnline = onSuccessResponse.response.online;
        let salesDataCard = onSuccessResponse.response.card;
        this.setState({
          salesTotalOrders: salesData.OrdersCount,
          salesTotalSales: salesData.GrossAmount,
          salesNetSales: salesData.NetSale,
          // salesGst: Number(salesData.GrossAmount) - Number(salesData.NetSale),
          salesCGST: salesData.CGST,
          salesSGST: salesData.SGST,
          salesAverage: salesData.Average,
          salesGrossCOD: salesDataCOD.GrossAmount,
          salesGrossOnline: salesDataOnline.GrossAmount,
          salesGrossCard: salesDataCard.GrossAmount,
        });
      },
      (onError) => {
        console.log(onError);
      }
    );
  };

  pdfCheck = () => {
    const doc = new jsPDF();
    // doc.autoTable({ html: "#my-table" });

    doc.text({ html: "#my-table" });
    doc.save(
      `${storeName}_SalesReport_${
        this.state.saleStartDate === ""
          ? this.state.date
          : this.state.salesStartDate
      } to ${
        this.state.saleEndDate === ""
          ? this.state.date
          : this.state.salesEndDate
      }.pdf`
    );
  };

  render() {
    // const dataSet1 = [
    //   {
    //     name: "Johson",
    //     amount: 30000,
    //     sex: "M",
    //     is_married: true,
    //   },
    //   {
    //     name: "Monika",
    //     amount: 355000,
    //     sex: "F",
    //     is_married: false,
    //   },
    //   {
    //     name: "John",
    //     amount: 250000,
    //     sex: "M",
    //     is_married: false,
    //   },
    //   {
    //     name: "Josef",
    //     amount: 450500,
    //     sex: "M",
    //     is_married: true,
    //   },
    // ];
    // console.log(this.state.date);
    // var date = new Date();
    // date.setDate(date.getDate() - 1);
    // var day = ("0" + date.getDate()).slice(-2);
    // var month = ("0" + (date.getMonth() + 1)).slice(-2); // fix 0 index
    // const { cashierLimit } = date.getYear() + 1900 + "-" + month + "-" + day;
    // console.log(typeof this.state.date);
    // console.log(dateFormat(this.state.date, "yyyy-mm-dd"));

    // const d = dateFormat(cashierLimit, "yyyy-mm-dd");
    // console.log(d);
    // this.setState({
    //   date: dateFormat(cashierLimit, "yyyy-mm-dd"),
    // });
    const { topTen, bottomTen, misc, hourly } = this.state;
    if (hourly === undefined || hourly === [] || hourly === null) {
    } else {
    }
    const userCheck = window.localStorage.getItem("access");
    let storeDet = JSON.parse(userCheck);
    let storeUserRole;
    if (storeDet !== null) {
      storeUserRole = storeDet.role;
    } else {
      storeUserRole = "";
    }
    return (
      <div className="content">
        <Card
          id="chartActivity"
          title="2014 Sales"
          category="All products including Taxes"
          stats="Data information certified"
          statsIcon="fa fa-check"
          content={
            <h3 style={{ textAlign: "left", padding: "1%" }}>
              {storeName} &nbsp; Analytics
            </h3>
          }
        />
        <Grid fluid>
          <Row>
            <Col lg={3} sm={4}>
              <div className="card card-stats" style={{ padding: "5%" }}>
                <div className="content">
                  <Row>
                    <Col xs={2}>
                      <div className="numbers text-center">
                        <h4
                          style={{
                            top: "10%",
                            left: "8%",
                            position: "absolute",
                          }}
                        >
                          Today
                        </h4>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <div className="numbers text-center">
                        <i className="pe-7s-server text-warning" />
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={6}>
                      <div className="numbers">
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Orders&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <br />
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gross&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <br />
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Net&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="numbers">
                        {misc !== ""
                          ? misc
                              .filter((q) => q.range === "Today")
                              .map((p, index) => (
                                <span
                                  key={index}
                                  style={{ position: "absolute", left: "70%" }}
                                >
                                  {p.OrdersCount}
                                  <br />
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {Math.round(p.final_amount)}
                                  <br />
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {Math.round(p.total)}
                                </span>
                              ))
                          : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={4}>
              <div className="card card-stats" style={{ padding: "5%" }}>
                <div className="content">
                  <Row>
                    <Col xs={2}>
                      <div className="numbers text-center">
                        <h4
                          style={{
                            top: "10%",
                            left: "8%",
                            position: "absolute",
                          }}
                        >
                          Yesterday
                        </h4>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <div className="numbers text-center">
                        <i className="pe-7s-server text-warning" />
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={2}>
                      <div className="numbers">
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Orders&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <br />
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gross&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <br />
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Net&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <div className="numbers">
                        {misc !== ""
                          ? misc
                              .filter((q) => q.range === "Yesterday")
                              .map((p, index) => (
                                <span
                                  key={index}
                                  style={{ position: "absolute", left: "70%" }}
                                >
                                  {p.OrdersCount}
                                  <br />
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {Math.round(p.final_amount)}
                                  <br />
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {Math.round(p.total)}
                                </span>
                              ))
                          : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={4}>
              <div className="card card-stats" style={{ padding: "5%" }}>
                <div className="content">
                  <Row>
                    <Col xs={2}>
                      <div className="numbers text-center">
                        <h4
                          style={{
                            top: "10%",
                            left: "8%",
                            position: "absolute",
                          }}
                        >
                          Last Week
                        </h4>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <div className="numbers text-center">
                        <i className="pe-7s-server text-warning" />
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={2}>
                      <div className="numbers">
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Orders&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <br />
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gross&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <br />
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Net&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <div className="numbers">
                        {misc !== ""
                          ? misc
                              .filter((q) => q.range === "Last Week")
                              .map((p, index) => (
                                <span
                                  key={index}
                                  style={{ position: "absolute", left: "70%" }}
                                >
                                  {p.OrdersCount}
                                  <br />
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {Math.round(p.final_amount)}
                                  <br />
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {Math.round(p.total)}
                                </span>
                              ))
                          : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={4}>
              <div className="card card-stats" style={{ padding: "5%" }}>
                <div className="content">
                  <Row>
                    <Col xs={2}>
                      <div className="numbers text-center">
                        <h4
                          style={{
                            top: "10%",
                            left: "8%",
                            position: "absolute",
                          }}
                        >
                          Last Month
                        </h4>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <div className="numbers text-center">
                        <i className="pe-7s-server text-warning" />
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={2}>
                      <div className="numbers">
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Orders&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <br />
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gross&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <br />
                        <span className="stats">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Net&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <div className="numbers">
                        {misc !== ""
                          ? misc
                              .filter((q) => q.range === "Last Month")
                              .map((p, index) => (
                                <span
                                  key={index}
                                  style={{ position: "absolute", left: "70%" }}
                                >
                                  {p.OrdersCount}
                                  <br />
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {Math.round(p.final_amount)}
                                  <br />
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {Math.round(p.total)}
                                </span>
                              ))
                          : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                id="chartActivity"
                title="2014 Sales"
                category="All products including Taxes"
                stats="Data information certified"
                statsIcon="fa fa-check"
                content={
                  <>
                    <h3
                      style={{
                        position: "relative",
                        left: "2%",
                        paddingTop: "2%",
                      }}
                    >
                      Sales Reports
                    </h3>
                    {/* <h3 style={{ textAlign: "left" }}>
                    &nbsp;&nbsp;&nbsp;Sale Reports<span style={{float:"right"}}>
                    
                    <ReactToPrint
                          // onAfterPrint={this.refreshPage}
                          trigger={() => (
                            <a
                              style={{
                                // position: "absolute",
                                // textAlign:"right",
                                // right: "35%",
                                // top: "95%",
                                // padding: "20px",
                              }}
                              //   type="button"
                              //   className="btn btn-primary"
                              href="#/"
                            >
                              print sales report
                            </a>
                          )}
                          content={() => this.componentRef}
                        />
                        
                        </span>
                        </h3>
                        
                     */}
                    <div className="content">
                      <p>
                        <input
                          className="col-sm-2.5 "
                          // style={{ float: "right" }}
                          type="date"
                          // className="form-control form-control-lg"
                          placeholder="provide Start Date"
                          // defaultValue={this.state.date}
                          onChange={this.onsalesStartDateChange}
                          name="salesStartDate"
                          style={{
                            position: "absolute",
                            right: "25%",
                            top: "7%",
                          }}
                          max={
                            // new Date(this.state.salesEndDate) >=
                            // new Date(this.state.salesStartDate)
                            //   ? this.state.salesStartDate
                            //   : this.state.date
                            // new Date(this.state.salesEnddate) <=
                            // new Date(this.state.date)
                            // ? this.state.date
                            // : this.state.salesEnddate
                            // this.state.salesEndDate
                            this.state.date
                          }
                          // min={
                          //   storeUserRole !== "SHOP"
                          //     ? null
                          //     : // dateFormat(cashierLimit, "yyyy-mm--dd")
                          //       // this.state.date
                          //       this.state.cashierLimit
                          // }
                          value={
                            this.state.salesStartDate !== ""
                              ? this.state.salesStartDate
                              : this.state.date
                            // new Date(this.state.salesEndDate) >=
                            //   new Date(this.state.salesStartDate)
                            // ? this.state.date
                            // : this.state.salesEndDate
                          }
                        />
                        <i
                          className="fa fa-arrow-right"
                          style={{
                            position: "absolute",
                            right: "20%",
                            top: "10%",
                          }}
                        ></i>
                        <span style={{ float: "right" }}>
                          <input
                            className="col-sm-2.5"
                            style={{
                              position: "absolute",
                              top: "7%",
                              right: "2%",
                            }}
                            type="date"
                            // className="form-control form-control-lg"
                            placeholder="provide Start Date"
                            // defaultValue={this.state.date}
                            onChange={this.onsalesEndDateChange}
                            name="salesEndDate"
                            max={this.state.date}
                            value={
                              this.state.salesEndDate === ""
                                ? this.state.date
                                : this.state.salesEndDate
                              // new Date(this.state.salesEndDate) <=
                              //     new Date(this.state.salesStartDate)
                              // ? this.state.salesStartDate
                              // : this.state.salesEndDate
                            }
                            // min={
                            //   storeUserRole !== "SHOP"
                            //     ? null
                            //     : // dateFormat(cashierLimit, "yyyy-mm--dd")
                            //       // this.state.date
                            //       this.state.cashierLimit
                            // }
                          />
                        </span>
                      </p>
                      <Row>
                        <Col xs={6}>
                          <div
                            className="numbers"
                            // style={{ position: "absolute", left: "5%" }}
                          >
                            <span className="stats">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gross
                              Sale&nbsp;&nbsp;&nbsp;
                            </span>
                            <br />
                            <span className="stats">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Net
                              Sale&nbsp;&nbsp;&nbsp;
                            </span>
                            <br />
                            <span className="stats">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CGST&nbsp;&nbsp;&nbsp;
                            </span>
                            <br />
                            <span className="stats">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SGST&nbsp;&nbsp;&nbsp;
                            </span>
                            <br />
                            <span className="stats">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;APC&nbsp;&nbsp;&nbsp;
                            </span>
                            <br />
                            <span className="stats">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cash
                              &nbsp;&nbsp;&nbsp;
                            </span>
                            <br />
                            <span className="stats">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Online
                              &nbsp;&nbsp;&nbsp;
                            </span>
                            <br />
                            <span className="stats">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Card
                              &nbsp;&nbsp;&nbsp;
                            </span>
                            <br />
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div
                            className="numbers"
                            // style={{ position: "relative", right: "-500%" }}
                          >
                            <span style={{ float: "right" }}>
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {Math.round(this.state.salesTotalSales)}
                              <br />
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {Math.round(this.state.salesNetSales)}
                              <br />
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {Math.round(this.state.salesCGST)}
                              <br />
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {Math.round(this.state.salesSGST)}
                              <br />
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {Math.round(this.state.salesAverage)}
                              <br />
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {Math.round(this.state.salesGrossCOD)}
                              <br />
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {Math.round(this.state.salesGrossOnline)}
                              <br />
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {Math.round(this.state.salesGrossCard)}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <div style={{ display: "none" }}>
                        <div ref={(el) => (this.componentRef = el)}>
                          <table id="my-table">
                            <thead>
                              <tr>
                                <th style={{ textAlign: "center" }}>
                                  {storeName}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {storeAddress ? (
                                <>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      {storeAddress.split(",")[0]},{" "}
                                      {storeAddress.split(",")[1]},
                                    </th>
                                  </tr>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      {storeAddress.split(",")[2]},{" "}
                                      {storeAddress.split(",")[3]},
                                    </th>
                                  </tr>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      {storeAddress.split(",")[4]},
                                      {storeAddress.split(",")[5]}
                                    </th>
                                  </tr>
                                </>
                              ) : null}
                              <tr>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <th>
                                  <strong>
                                    {this.state.saleStartDate === ""
                                      ? this.state.date
                                      : this.state.salesStartDate}
                                  </strong>{" "}
                                  to{" "}
                                  <strong>
                                    {this.state.salesEndDate === ""
                                      ? this.state.date
                                      : this.state.salesEndDate}
                                  </strong>
                                </th>
                              </tr>
                              <tr
                                className="stats"
                                style={{
                                  position: "relative",
                                  right: "10%",
                                }}
                              >
                                <th>&nbsp;&nbsp;Gross Sale&nbsp;&nbsp;</th>
                                <td
                                  style={{
                                    position: "relative",
                                    right: "20%",
                                  }}
                                >
                                  ₹{Math.round(this.state.salesTotalSales)}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr className="stats">
                                <th>&nbsp;&nbsp;Net Sale&nbsp;&nbsp;</th>
                                <td
                                  style={{
                                    position: "relative",
                                    right: "20%",
                                  }}
                                >
                                  ₹{Math.round(this.state.salesNetSales)}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr className="stats">
                                <th>&nbsp;&nbsp;CGST&nbsp;&nbsp;</th>
                                <td
                                  style={{
                                    position: "relative",
                                    right: "20%",
                                  }}
                                >
                                  ₹{Math.round(this.state.salesCGST)}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr className="stats">
                                <th> &nbsp;&nbsp;SGST&nbsp;&nbsp;</th>
                                <td
                                  style={{
                                    position: "relative",
                                    right: "20%",
                                  }}
                                >
                                  ₹{Math.round(this.state.salesSGST)}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr className="stats">
                                <th>&nbsp;&nbsp;APC&nbsp;&nbsp;</th>
                                <td
                                  style={{
                                    position: "relative",
                                    right: "20%",
                                  }}
                                >
                                  ₹{Math.round(this.state.salesAverage)}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr className="stats">
                                <th>&nbsp;&nbsp;Cash &nbsp;&nbsp;</th>
                                <td
                                  style={{
                                    position: "relative",
                                    right: "20%",
                                  }}
                                >
                                  ₹{Math.round(this.state.salesGrossCOD)}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr className="stats">
                                <th>&nbsp;&nbsp;Online &nbsp;&nbsp;</th>
                                <td
                                  style={{
                                    position: "relative",
                                    right: "20%",
                                  }}
                                >
                                  ₹{Math.round(this.state.salesGrossOnline)}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr className="stats">
                                <th>&nbsp;&nbsp;Card &nbsp;&nbsp;</th>
                                <td
                                  style={{
                                    position: "relative",
                                    right: "20%",
                                  }}
                                >
                                  ₹{Math.round(this.state.salesGrossCard)}
                                </td>
                              </tr>
                            </tbody>
                            <tr>
                              <td>Printed on {this.state.dateTime}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      {/* <a 
                        style={{color:"red",position:"absolute",right:"12%",top:"85%"}}
                        href="#/"
                        style={{
                          color: "red",
                          position: "absolute",
                          right: "12%",
                          top: "85%",
                        }}
                        rel="noopener noreferrer"
                        onClick={this.pdfCheck}
                        ><i className="fa fa-file-pdf-o fa-lg"></i></a> */}
                      {/* <ExcelFile
                          filename="TopTen"
                          element={<a href="#/">Excel Download</a>}
                        >
                          <ExcelSheet data={topTen} name="Top 10">
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Quantity" value="count" />
                            <ExcelColumn label="Amount" value="amount" />
                            {/* <ExcelColumn
                              label="Marital Status"
                              value={(col) =>
                                col.is_married ? "Married" : "Single"
                              }
                            /> */}
                      {/* </ExcelSheet>
                        </ExcelFile>  */}
                      <ReactToPrint
                        // onAfterPrint={this.refreshPage}
                        trigger={() => (
                          <a
                            rel="noopener noreferrer"
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "78%",
                              padding: "20px",
                            }}
                            //   type="button"
                            //   className="btn btn-primary"
                            href="#/"
                            className="fa fa-print fa-lg"
                          >
                            &nbsp;
                          </a>
                        )}
                        content={() => this.componentRef}
                      />
                      {/* <h3 style={{ textAlign: "center" }}> */}
                      {/* {this.state.salesDate ?s this.state.salesDate : null} */}
                      {/* <a
                                  href={
                                    clientHost.split("/backend/api")[0] +
                                    // `/reports/Report/dailySale`
                                    `/reports/Report/dailySalesReport?storeId=${storeIdValue}&startDate=${this.state.salesStartDate}&endDate=${this.state.salesEndDate}`
                                  }
                                  rel="noopener noreferrer"
                                  target="_blank"
                                > */}
                      {/* [ Download ]
                                </a>
                              </h3> */}
                    </div>
                  </>
                }
              />
            </Col>
          </Row>
          {storeUserRole === "SHOP" ? null : (
            <>
              {/* <Row>
                <Col md={12}>
                  <Card
                    id="chartActivity"
                    title="2014 Sales"
                    category="All products including Taxes"
                    stats="Data information certified"
                    statsIcon="fa fa-check"
                    content={
                      <h3 style={{ textAlign: "left" }}>
                        &nbsp;&nbsp;&nbsp;Popularity Report
                      </h3>
                    }
                  />
                </Col>
              </Row> */}
              <Row>
                <Col md={6}>
                  <Card
                    title="Striped Table with Hover"
                    category="Here is a subtitle for this table"
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <>
                        <div
                          className="header"
                          style={{
                            padding: "3%",
                            backgroundColor: "lightgreen",
                            color: "Black",
                          }}
                        >
                          <h4 className="title">
                            &nbsp;&nbsp;&nbsp;Best Selling Items
                          </h4>
                        </div>
                        <Table striped hover>
                          <thead style={{ color: "black" }}>
                            <tr>
                              <th>Rank</th>
                              <th>Name</th>
                              <th>Sold Count</th>
                              <th>Sales</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topTen
                              ? topTen !== []
                                ? topTen.map((p, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{p.name}</td>
                                        <td>{p.count}</td>
                                        <td>
                                          {" "}
                                          <i
                                            className={CURRENCY}
                                            aria-hidden="true"
                                          ></i>
                                          {Math.round(p.amount)}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null
                              : null}
                          </tbody>
                        </Table>
                      </>
                    }
                  />
                </Col>
                <Col md={6}>
                  <Card
                    title="Striped Table with Hover"
                    category="Here is a subtitle for this table"
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <>
                        <div
                          className="header"
                          style={{
                            padding: "3%",
                            backgroundColor: "#d44000",
                            color: "white",
                          }}
                        >
                          <h4 className="title">
                            &nbsp;&nbsp;&nbsp;Least Selling Items
                          </h4>
                        </div>
                        <Table striped hover>
                          <thead style={{ color: "black" }}>
                            <tr>
                              <th>Rank</th>
                              <th>Name</th>
                              <th>Sold Count</th>
                              <th>Sales </th>
                            </tr>
                          </thead>
                          <tbody>
                            {bottomTen
                              ? bottomTen !== []
                                ? bottomTen.map((p, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{p.name}</td>
                                        <td>{p.count}</td>
                                        <td>
                                          {" "}
                                          <i
                                            className={CURRENCY}
                                            aria-hidden="true"
                                          ></i>
                                          {Math.round(p.amount)}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null
                              : null}
                          </tbody>
                        </Table>
                      </>
                    }
                  />
                </Col>
              </Row>

              <Col md={12}>
                <Card
                  id="chartActivity"
                  title="2014 Sales"
                  category="All products including Taxes"
                  stats="Data information certified"
                  statsIcon="fa fa-check"
                  content={
                    <>
                      <h3 style={{ textAlign: "left" }}>
                        &nbsp;&nbsp;&nbsp;Monthly Orders Report
                      </h3>
                      <div className="line-chart-example card">
                        <div className="card-body">
                          <Chart21
                            location="Massachusetts"
                            legendPosition="bottom"
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </Col>
              <Col md={12}>
                <Card
                  id="chartActivity"
                  title="2014 Sales"
                  category="All products including Taxes"
                  stats="Data information certified"
                  statsIcon="fa fa-check"
                  content={
                    <>
                      <h3 style={{ textAlign: "left" }}>
                        &nbsp;&nbsp;&nbsp;Monthly Sales Report
                      </h3>
                      <div className="line-chart-example card">
                        <div className="card-body">
                          <Chart22
                            location="Massachusetts"
                            legendPosition="bottom"
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </Col>
              <Col md={12}>
                <Card
                  id="chartActivity"
                  title="Monthly Expenses Report"
                  category="All products including Taxes"
                  stats="Data information certified"
                  statsIcon="fa fa-check"
                  content={
                    <>
                     <div style={{display: 'flex', justifyContent:'space-between', padding: '8px 20px', flexWrap: 'wrap'}}>
                     <h3 style={{ textAlign: "left" }}>
                        Monthly Expenses Report
                      </h3>
                      <div>
                        <select title="Select Expenses Data Bases on Year" className="form-control" id="year" name="year" value={this.state.currentYear} onChange={this.handleYearChange}>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                     </div>
                      <div className="line-chart-example card">
                        <div className="card-body">
                          <ExpensesChart
                            location="Massachusetts"
                            legendPosition="bottom"
                            currentYear = {this.state.currentYear}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </Col>
              {/* <Col md={12}>
                <Card
                  id="chartActivity"
                  title="2014 Sales"
                  category="All products including Taxes"
                  stats="Data information certified"
                  statsIcon="fa fa-check"
                  content={
                    <>
                      <h3 style={{ textAlign: "left" }}>
                        &nbsp;&nbsp;MBA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;upload
                        file&nbsp;
                        <div className="search-box">
                          <input
                            type="file"
                            style={{ width: "250px" }}
                            onChange={(e) => this.handleBulk(e)}
                          />
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            data-toggle="modal"
                            // data-target="#myModal"
                            className="btn btn-primary"
                            onClick={this.getAnalytics}
                          >
                            Bulk Upload
                          </button>
                        </div>
                      </h3>
                      <div className="line-chart-example card">
                        <div className="card-body">
                          <Chart
                            location="Massachusetts"
                            legendPosition="bottom"
                            analyticsData={this.state.analyticsData}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </Col> */}
            </>
          )}
        </Grid>
        <section className="feeds no-padding-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="line-chart-example card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">MBA</h3>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;upload file
                    <span>
                      <input
                        type="file"
                        style={{ width: "250px" }}
                        onChange={(e) => this.handleBulk(e)}
                      />
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        data-toggle="modal"
                        // data-target="#myModal"
                        className="btn btn-primary"
                        onClick={this.getAnalytics}
                      >
                        Get MBA data
                      </button>
                    </span>
                    {/* <input
                      //  style={{width:"30%",postion:"absolute;",float:"right"}}
                      type="date"
                      // className="form-control"
                      // // defaultValue={props.date}
                      // value={props.HourlySalesDate}
                      // name="getSalesByDate"
                      // max={props.date}
                      // onChange={props.getHourlySalesByDate}
                    /> */}
                  </div>
                  <div className="card-body">
                    <Chart
                      location="Massachusetts"
                      legendPosition="bottom"
                      analyticsData={this.state.analyticsData}
                    />
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                      //  style={{ height: "400px", overflow: "auto" }}
                      >
                        <table className="table table-striped table-sm">
                          <thead>
                            <tr>
                              {/* <th>Items</th> */}
                              <th>Items</th>
                              <th>confidence</th>
                              <th>Lift</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.analyticsData ? (
                              this.state.analyticsData.items_list ? (
                                this.state.analyticsData.items_list.map(
                                  (p, index) => {
                                    return (
                                      <tr>
                                        <th>
                                          {p.item1}, {p.item2}
                                        </th>
                                        <th>
                                          {Number(p.confidence).toFixed(2)}
                                        </th>
                                        <th>{Number(p.lift).toFixed(2)}</th>
                                      </tr>
                                    );
                                  }
                                )
                              ) : null
                            ) : (
                              <tr>
                                <th>No data</th>
                                <th></th>
                                <th></th>
                              </tr>
                            )}
                            {/* ) : (
                            <tr style={{ height: "353px" }}>
                              <td></td>
                              <td></td>
                              <td style={{ paddingTop: "150px" }}>
                                <h1 style={{ fontSize: "35px" }}> No Data </h1>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            ) */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
  handleBulk = (e) => {
    let file = e.target.files[0];
    this.setState({ file });
  };
  getAnalytics = () => {
    let file = this.state.file;
    let url = `http://ec2-3-128-126-18.us-east-2.compute.amazonaws.com:5000/mba`;
    if (file !== "") {
      let formdata = new FormData();
      formdata.append("document", file);
      // formdata.append("storeid", storeFresh.storeId);
      axios({
        url: url,
        method: "POST",
        data: formdata,
      }).then(
        (res) => {
          this.setState({ analyticsData: res.data });
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      alert("upload file");
    }
    // let url = `http://ec2-3-128-126-18.us-east-2.compute.amazonaws.com:5000/mba`;
    // let requestBody = { document: "" };
    // axios
    //   .create({
    //     responseType: "json",
    //     // headers: {
    //     //   "content-type": "application/x-www-form-urlencoded",
    //     //   "x-api-key": key,
    //     // },
    //   })
    //   .post(url, qs.stringify(requestBody))
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  async componentDidMount() {
    this.getDateFormat();
    let date3 = new Date();
    let dateFormatStart =
      date3.getFullYear() +
      "-" +
      (date3.getMonth() + 1) +
      "-" +
      date3.getDate();
    let hDate = this.state.hDate !== "" ? this.state.hDate : dateFormatStart;
    var apiPath9 = new Uri(clientHost + HOURLY_REPORT)
      .addQueryParam("store_id", storeIdValue)
      // .addQueryParam("date", dateFormatStart);
      .addQueryParam("date", hDate);
    getResponseClient(
      apiPath9,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          hourly: onSuccessResponse.response,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
    var apiPath8 = new Uri(clientHost + TOP10_ITEMS).addQueryParam(
      "store_id",
      storeIdValue
    );
    getResponseClient(
      apiPath8,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          topTen: onSuccessResponse.response,
          bottomTen: onSuccessResponse.responses,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
    var apiPath7 = new Uri(clientHost + MISC).addQueryParam(
      "store_id",
      storeIdValue
    );
    getResponseClient(
      apiPath7,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          misc: onSuccessResponse.response,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
    var apiPath6 = new Uri(clientHost + `Report/getSalesData`)
      .addQueryParam("storeId", storeIdValue)
      .addQueryParam("startDate", dateFormatStart)
      .addQueryParam("endDate", dateFormatStart);
    getResponseClient(
      apiPath6,
      storeFresh.key,
      (onSuccessResponse) => {
        let salesData = onSuccessResponse.response.all;
        let salesDataCOD = onSuccessResponse.response.cod;
        let salesDataOnline = onSuccessResponse.response.online;
        let salesDataCard = onSuccessResponse.response.card;
        this.setState({
          totalOrders: salesData.OrdersCount,
          totalSales: salesData.GrossAmount,
          netSales: salesData.NetSale,
          // gst: Number(salesData.GrossAmount) - Number(salesData.NetSale),
          CGST: salesData.CGST,
          SGST: salesData.SGST,
          average: salesData.Average,
          grossCOD: salesDataCOD.GrossAmount,
          netCOD: salesDataCOD.NetSale,
          grossOnline: salesDataOnline.GrossAmount,
          netOnline: salesDataOnline.NetSale,
          grossCard: salesDataCard.GrossAmount,
          netCard: salesDataCard.NetSale,
          salesTotalOrders: salesData.OrdersCount,
          salesTotalSales: salesData.GrossAmount,
          salesNetSales: salesData.NetSale,
          salesCGST: salesData.CGST,
          salesSGST: salesData.SGST,
          salesAverage: salesData.Average,
          salesGrossCOD: salesDataCOD.GrossAmount,
          salesGrossOnline: salesDataOnline.GrossAmount,
          salesGrossCard: salesDataCard.GrossAmount,
        });
      },
      (onError) => {
        console.log(onError);
      }
    );
  }
}
export default Reports;
